import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { global as $$ } from "strings";
import { Input } from "components/Form";
import { deleteAccountV2 } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { useHistory } from "react-router-dom";
import AlertSuccess from "../../../components/AlertSuccess";
import { Modal } from "pages/talent/components";

const DeleteTalentModal = ({ onClose, data }) => {
  const alert = useAlert();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const onDelete = async () => {
    const call = async () => {
      try {
        setSubmitting(true);
        await deleteAccountV2(id);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {
          timeout: 2000
        });
        history.push(`/admin/talent/`);
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  return (
    <Modal
      title="Delete Talent"
      saveBtnText="Delete"
      onClose={onClose}
      onClickSave={onDelete}
      saveBtnDisabled={value !== data.fullname || submitting}
      buttonsDisabled={submitting}
    >
      <div className="flex w-full flex-col gap-2.5" noValidate>
        <div>
          Are you sure you want to delete the talent record {data.fullname}? If
          yes, enter the full name of the record in this text box and press
          Delete. If you want to recover the profile, you will need to{" "}
          <a
            href="mailto:talent@rightsideup.co"
            className="text-link underline hover:text-link-dark"
          >
            contact support
          </a>
          .
        </div>
        <Input
          onChange={(e) => setValue(e.target.value)}
          placeholder="Enter Full Name"
        />
      </div>
    </Modal>
  );
};

export default DeleteTalentModal;
