import React from "react";
import { FormErrorMessage, Label, Sublabel } from "../components";

/** A wrapper around an input that provides a label, optional sublabel, and errors */
export const FormElement = ({ children, label, sublabel, errors }) => {
  return (
    <div className="w-full flex-col">
      <Label>{label}</Label>
      {sublabel && <Sublabel>{sublabel}</Sublabel>}
      {children}
      <FormErrorMessage error={errors} />
    </div>
  );
};
