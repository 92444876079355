import React from "react";
import clsx from "clsx";
import completeness_red from "assets/completeness_red.png";
import completeness_yellow from "assets/completeness_yellow.png";
import completeness_blue from "assets/completeness_blue.png";
import { Tooltip } from "components_v2/Tooltip";
import { FieldMappingToolTip } from "components_v2";

export const TalentCompleteness = ({ talentProfileCompletion }) => {
  if (!talentProfileCompletion) return null;

  const {
    percentage,
    hasPhoto,
    hasAboutMe,
    hasSkills,
    hasIndustries,
    hasTools,
    projectsWithResults
  } = talentProfileCompletion;

  const getComplete = (isComplete) => (isComplete ? "Complete" : "Incomplete");

  return (
    <>
      <div
        data-tooltip-id="tooltip-talent-completeness"
        className={clsx(
          "flex items-center gap-1 text-xs",
          percentage >= 80
            ? "text-electric-indigo"
            : percentage >= 50
              ? "text-orange-darker"
              : "text-red-darker"
        )}
      >
        <img
          className="mr-1.25 h-4"
          src={
            percentage >= 80
              ? completeness_blue
              : percentage >= 50
                ? completeness_yellow
                : completeness_red
          }
          alt="Completeness"
        />
        {percentage}%&nbsp;complete
      </div>
      <Tooltip id="tooltip-talent-completeness">
        <FieldMappingToolTip
          values={{
            "Profile Photo": getComplete(hasPhoto),
            "About Me": getComplete(hasAboutMe),
            "Displayed Skills": getComplete(hasSkills),
            "Displayed Tools": getComplete(hasTools),
            "Displayed Industries": getComplete(hasIndustries),
            "Projects with Results":
              projectsWithResults >= 3 ? "Complete" : `${projectsWithResults}/3`
          }}
        />
      </Tooltip>
    </>
  );
};
