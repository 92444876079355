import { Tooltip as ReactTooltip } from "react-tooltip";

/**
 * This is a tooltip that is context aware and can position itself wisely.
 * To use it place it *alongside* the component that is the trigger. The
 * children are the tooltip content itself.
 *
 * The trigger component should have a prop added: data-tooltip-id={id}.
 * This is the id passed to the tooltip.
 */
export const Tooltip = ({ id, children }) => {
  return (
    <ReactTooltip
      id={id}
      opacity={1}
      border="1px solid #E8EBF8"
      place="top"
      style={{
        backgroundColor: "white",
        padding: "8px",
        borderRadius: "7px",
        boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.08)",
        zIndex: 99
      }}
    >
      {children}
    </ReactTooltip>
  );
};
