import clsx from "clsx";
import userImg from "assets/user.svg";
import { admin_talent_header as $ } from "strings";

const getStatus = (data) => {
  if (data.excluded) {
    return { label: $.excluded_label, color: "red" };
  }
  if (data.availability_utilization === 40)
    return { label: $.fully_utilized_label, color: "orange-darker" };
  if (data.availability_utilization > 40)
    return { label: $.overutilized_label, color: "orange-darker" };
  if (data.availability_utilization < 40 && data.projects_count > 0)
    return {
      label: $.on_active_projects_label,
      color: "yellow-dark"
    };
  return { label: null, color: null };
};

export const TalentPhotoWithUtilization = ({ data }) => {
  const { label, color } = getStatus(data);
  const hasStatus = label !== null;

  return (
    <div
      className={clsx(
        "flex-start mb-auto flex flex-grow-0 flex-col rounded-md",
        hasStatus && "p-1",
        color && `bg-${color}`
      )}
    >
      <img
        src={data.profile_photo || userImg}
        className={clsx("h-20 w-20 rounded-md")}
        alt={data.fullname}
      />
      {label && (
        <div className="w-20 pt-1 text-center font-lato text-xs text-white">
          {label}
        </div>
      )}
    </div>
  );
};
