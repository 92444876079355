import React, { useState, useEffect } from "react";
import clsx from "clsx";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import UilLoading from "@iconscout/react-unicons/icons/uil-spinner";

const Indicator = ({ state }) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setHidden(false);
    let timeoutId;
    if (state !== "saving") {
      timeoutId = setTimeout(() => setHidden(true), 2000);
    }

    // Cleanup function to prevent state updates on unmounted component
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [state]);

  return (
    <span
      className={clsx(
        "ml-2 text-midnight transition-opacity",
        hidden ? "opacity-0" : ""
      )}
    >
      {state === "saving" && <UilLoading className="animate-spin" size="16" />}
      {state === "saved" && <UilCheck className="text-green-600" size="16" />}
      {state === "error" && <UilTimes className="text-red" size="16" />}
    </span>
  );
};

export default Indicator;
