import { Tooltip } from "components_v2/Tooltip";
import UilLoading from "@iconscout/react-unicons/icons/uil-spinner";
import clsx from "clsx";
import { toFormat } from "utils/date";
import { EditableField } from "components_v2";
import useBreakpoint from "hooks/useBreakpoint";

export const YES_NO_OPTIONS = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

/**
 * A simple section/card container to standardize the size/spacing/title of
 * the sections in the talent detail pane.
 */
export const TalentDetailSection = ({ children, title }) => {
  return (
    <div className="flex w-full min-w-0 flex-col gap-2.5 rounded-lg bg-lightest-grey p-2.5">
      <div className="text-lg font-bold text-midnight">{title}</div>
      {children}
    </div>
  );
};

/**
 * The talent detail pane has a bunch of details about the talent. A Field
 * corresponds to a line in a section, that displays the name of the data
 * and the data itself, which is editable on click. A couple of fields override
 * the default behavior:
 *
 * stackLeft - instead of justifying between - justify left
 * editable - defaults to true. But if false, displays value only
 * customWrapper - render component that wraps the editable
 */
export const Field = ({
  name,
  value,
  loading,
  placeholder,
  options, // for select or multiselect
  onChange,
  tooltipIcon,
  tooltipContent,
  displayFormat = "MM/dd/yyyy",
  displayTextColor = "text-midnight",
  isBoolean,
  isNumber,
  isMulti,
  isDate,
  isPhone,
  isDollar,
  customWrapper, // somethings have like suffix text
  stackLeft,
  forceColumnLayout = false,
  editable = true
}) => {
  const { isAtMost } = useBreakpoint();
  const formatValueForDisplay = (val) => {
    let displayValue = val;
    if (displayValue !== undefined && displayValue !== null && isBoolean) {
      displayValue = YES_NO_OPTIONS.find((v) => v.value === value).label;
    }
    if (displayValue !== undefined && options) {
      if (isMulti) {
        displayValue = Array.isArray(val)
          ? value
              .map((v) => options.find((o) => o.value === v)?.label)
              .join(", ")
          : displayValue;
      } else {
        displayValue =
          options.find((o) => o.value === val)?.label ?? displayValue;
      }
    }
    if (displayValue !== undefined && isDate) {
      displayValue = toFormat(new Date(val), displayFormat);
    }

    if (displayValue && isDollar) {
      displayValue = `$${displayValue}`;
    }

    return displayValue;
  };

  const columnLayout = forceColumnLayout || isAtMost("lg");

  const content = (
    <EditableField
      formatValueForDisplay={formatValueForDisplay}
      options={options || (isBoolean ? YES_NO_OPTIONS : undefined)}
      placeholder={placeholder}
      value={value}
      isDate={isDate}
      isBoolean={isBoolean}
      isMulti={isMulti}
      isNumber={isNumber}
      isPhone={isPhone}
      onChange={onChange}
      displayTextColor={displayTextColor}
      justifyLeft={stackLeft || columnLayout}
      editable={editable}
    />
  );

  return (
    <div
      className={clsx(
        "flex w-full min-w-0 cursor-pointer text-sm font-medium",
        columnLayout ? "flex-col" : "flex-row",
        stackLeft ? "gap-x-1 gap-y-0" : "gap-x-2.5 gap-y-1"
      )}
    >
      <div className="flex shrink-0 flex-row gap-1">
        <div className="shrink-0 whitespace-nowrap text-left text-kasmir">
          {name}
        </div>

        {tooltipIcon && (
          <>
            <div
              data-tooltip-id={`tooltip-${name.replace(" ", "_")}`}
              className="align-center flex h-5 w-5 items-center justify-center rounded-full bg-white"
            >
              {tooltipIcon}
            </div>
            {tooltipContent && (
              <Tooltip id={`tooltip-${name.replace(" ", "_")}`}>
                {tooltipContent}
              </Tooltip>
            )}
          </>
        )}
      </div>
      <div
        className={clsx(
          "flex min-w-0 flex-1",
          columnLayout
            ? "text-left"
            : stackLeft
              ? "text-left"
              : "ml-auto justify-end text-right",
          columnLayout ? "w-full" : ""
        )}
      >
        {loading ? (
          <UilLoading className="animate-[spin_2s_linear_infinite]" size="12" />
        ) : customWrapper && value ? (
          customWrapper(content)
        ) : (
          content
        )}
      </div>
    </div>
  );
};
