import { useContext } from "react";
import { TalentContext } from "../TalentDataContext";
import { Field, TalentDetailSection } from "./components";
import { COMMUNICATION_PREFERENCES_OPTIONS } from "pages/talent/Settings/ContactInformation";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getTalentAgreements, patchUser } from "utils/adminApi";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import AlertSuccess from "../../../../../components/AlertSuccess";
import AlertError from "components/AlertError";


export default function Contact() {
  const { talentData, onUpdateTalent, id, loading } = useContext(TalentContext);
  const queryClient = useQueryClient();

  const patchUserEmail = useMutation({
    mutationFn: async (email) => {
      // Optimistically update
      queryClient.setQueryData(["talentAccount", id], {
        email: email,
        primary_talent_email: email
      });
      patchUser(id, { primary_talent_email: email, email });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["talentAccount", id] });
    },
    onError: (e, variables) => {
      const duplicateId = e.response?.data?.users?.[0]?.id;
      if (duplicateId) {
        const pageUrl = `/admin/contacts/${duplicateId}/contact_info`;
        alert.info(
          <AlertSuccess>
            <div>
              A record with this email already exists.{" "}
              <a href={pageUrl} className="underline">
                Click here to view it.
              </a>
            </div>
          </AlertSuccess>
        );
      } else {
        alert.error(
          <AlertError
            error={e}
            onRetry={() => patchUserEmail.mutateAsync(variables)}
          />
        );
      }
    }
  });

  const { data: agreementsData } = useQuery({
    queryFn: () => getTalentAgreements(id),
    queryKey: ["admin_talent_agreements", id]
  });

  const smsOptIn = agreementsData?.textmessage;

  const onSaveEmail = async (email) => {
    if (!email) return;

    await patchUserEmail.mutateAsync(email);
  };

  return (
    <TalentDetailSection title="Contact Info">
      <Field
        name="User Email"
        placeholder="Enter Email"
        value={talentData.primary_talent_email}
        displayTextColor="text-electric-indigo break-all" // emails don't have built in break chars
        type="email"
        onChange={onSaveEmail}
        loading={loading}
      />
      <Field
        name="Phone Number"
        placeholder="Enter Phone Number"
        displayTextColor="text-electric-indigo"
        value={talentData.phone_number}
        isPhone
        onChange={(v) => onUpdateTalent({ phone_number: v })}
        tooltipIcon={smsOptIn ? <UilCheck size="16" /> : null}
        tooltipContent={
          smsOptIn ? (
            <div className="text-sm text-midnight">SMS Opt-In Confirmed</div>
          ) : null
        }
        loading={loading}
      />
      <Field
        name="Preferred Communication"
        placeholder="Select Preferred Communication"
        isMulti
        value={talentData.communication_preferences?.split("|") ?? []}
        onChange={(v) =>
          onUpdateTalent({ communication_preferences: v.join("|") })
        }
        options={COMMUNICATION_PREFERENCES_OPTIONS}
        loading={loading}
        forceColumnLayout
      />
    </TalentDetailSection>
  );
}
