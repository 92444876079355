import DropdownSelect from "components/DropdownMenuButton";
import Availability from "./Availability";
import Basics from "./Basics";
import Clockify from "./Clockify";
import Contact from "./Contact";
import Onboarding from "./Onboarding";
import ProfileCard from "./ProfileCard";
import { useContext } from "react";
import { TalentContext } from "../TalentDataContext";

/**
 * The scrolly pane on the left that shows the talent details.
 */
const TalentDetailPane = ({ data, setModal }) => {
  const { id, talentData, onUpdateTalent } = useContext(TalentContext);

  const actionOptions = [
    {
      label: talentData.onboard_last_email_at
        ? "Resend Talent Account Invite"
        : "Send Talent Account Invite",
      onClick: () => setModal({ type: "invite_talent" })
    },
    {
      label: "Merge Profile",
      onClick: () => {
        window.location.href = `/admin/talent/${id}/merge`;
      }
    },
    {
      label: talentData.excluded ? "Include in Search" : "Exclude from Search",
      onClick: () =>
        talentData.excluded
          ? onUpdateTalent({
              excluded: false,
              excluded_reason: null
            })
          : setModal({ type: "exclude_talent" })
    },
    {
      label: "Delete Talent",
      onClick: () => setModal({ type: "delete_talent" })
    }
  ];

  return (
    <div className="scrollbar flex h-full w-full flex-col gap-2.5 overflow-y-auto rounded-xl bg-white p-2.5 pb-8">
      {data && (
        <>
          <DropdownSelect label="Actions" compact options={actionOptions} />
          <ProfileCard />
          <Basics />
          <Contact />
          <Availability />
          <Onboarding />
          <Clockify />
        </>
      )}
    </div>
  );
};

export default TalentDetailPane;
