import { useContext, useState, useMemo, useEffect } from "react";
import clsx from "clsx";
import { TalentPhotoWithUtilization } from "components_v2";
import { TalentContext } from "../TalentDataContext";
import { Field } from "./components";
import EditableDoubleField from "components/EditableDoubleField";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getTalentProjects,
  patchTalentProject,
  postTalentProject
} from "utils/adminApi";
import { toFormat } from "utils/date";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import AlertSuccess from "components/AlertSuccess";
import AddressInput from "components/AddressInput";
import LogoIcon from "components/icons/Logo";
import { Link } from "react-router-dom";
import { withHttp } from "utils/str";
import { Tooltip } from "components_v2/Tooltip";
import LinkedIn from "components/icons/links/LinkedIn.svg";
import Calendar from "components/icons/links/Calendar.svg";
import Email from "components/icons/links/Email.svg";
import Phone from "components/icons/links/Phone.svg";

const CurrentRoleEdit = ({ title, id, setEditting }) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const [currentRole, setCurrentRole] = useState({
    currentTitle: title ? title.split(" at ")[0] : "",
    currentCompany: title ? title.split(" at ")[1] : ""
  });

  useEffect(() => {
    setCurrentRole({
      currentTitle: title ? title.split(" at ")[0] : "",
      currentCompany: title ? title.split(" at ")[1] : ""
    });
  }, [title]);

  const { data: projects } = useQuery({
    queryKey: ["talentProjects", id],
    queryFn: () => getTalentProjects(id, { page_size: 9999 })
  });

  const updateProjectMutation = useMutation({
    mutationFn: ({ project_id, name, company }) =>
      patchTalentProject(project_id, { name, company }),
    onError: (error, variables) => {
      alert.error(
        <AlertError
          error={error}
          onRetry={() => updateProjectMutation.mutateAsync(variables)}
        />
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["talentProjects", id] })
  });

  const createProjectMutation = useMutation({
    mutationFn: ({ name, company, id }) =>
      postTalentProject({
        name,
        company,
        user: id,
        active: true,
        start_date: toFormat(new Date(), "yyyy-MM-dd"),
        description: name
      }),
    onError: (error, variables) => {
      alert.error(
        <AlertError
          error={error}
          onRetry={() => createProjectMutation.mutateAsync(variables)}
        />
      );
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["talentProjects", id] })
  });

  const activeProject = useMemo(() => {
    if (!projects) return;
    if (projects.results && Array.isArray(projects.results)) {
      return projects.results.find((p) => p.active === true);
    }
  }, [projects]);

  const updateRole = async (name, company) => {
    await updateProjectMutation.mutateAsync({
      project_id: activeProject?.id,
      name,
      company
    });
  };

  const createRole = async (name, company) => {
    if (!name || !company) return;
    await createProjectMutation.mutateAsync({
      id,
      name,
      company
    });
  };

  const onSaveRole = (name, company) => {
    setCurrentRole({ currentTitle: name, currentCompany: company });
    activeProject ? updateRole(name, company) : createRole(name, company);
  };

  return (
    <>
      <EditableDoubleField
        className="w-full"
        first={currentRole?.currentTitle}
        last={currentRole?.currentCompany}
        firstPlaceholder="Role Unknown"
        lastPlaceholder="Company"
        firstRequired={true}
        lastRequired={false}
        divider={"at"}
        onChange={(first, last) => {
          onSaveRole(first, last);
        }}
        inputClass="w-full h-6"
        indicator="role"
        data-tooltip-id={`tooltip-current-role`}
        notifyIsEditting={setEditting}
      />
      {currentRole.currentTitle && currentRole.currentCompany && (
        <Tooltip id="tooltip-current-role">
          <div className="max-w-50 text-wrap text-sm text-midnight">{`${currentRole.currentTitle} at ${currentRole.currentCompany}`}</div>
        </Tooltip>
      )}
    </>
  );
};

export default function ProfileCard() {
  const alert = useAlert();
  const { talentData, onUpdateTalent, id, updateAccount } =
    useContext(TalentContext);
  const [editting, setEditting] = useState(false);

  const links = [
    {
      name: "linkedin",
      icon: (
        <img
          src={LinkedIn}
          size="16"
          alt="linkedin"
          className="svg-midnight-to-electric-indigo"
        />
      ),
      url: talentData?.linkedin_url
    },
    {
      name: "public",
      icon: <LogoIcon size="16" />,
      url: `${window.location.origin}/talent/${talentData.url_suffix}`
    },
    {
      name: "phone",
      icon: <img src={Phone} size="16" alt="phone" />,
      value: talentData.phone_number,
      clickToCopy: true
    },
    {
      name: "calendar",
      icon: (
        <img
          src={Calendar}
          height="16"
          width="16"
          alt="calendar"
          className="svg-midnight-to-electric-indigo"
        />
      ),
      url: talentData?.calendar_link
    },
    {
      name: "email",
      icon: <img src={Email} size="16" alt="email" />,
      value: talentData.primary_talent_email,
      clickToCopy: true
    }
  ];

  const onSaveLocation = async (location) => {
    const {
      full_address,
      location_id,
      city,
      state,
      state_code,
      country,
      zipcode,
      timezone_id,
      timezone_name
    } = location;

    await updateAccount.mutateAsync({
      full_address,
      location_id,
      city,
      state,
      state_code,
      country,
      zipcode,
      timezone_id,
      timezone_name
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert.success(<AlertSuccess message="Copied!" />, { timeout: 2000 });
    });
  };

  return (
    <div className="flex w-full flex-col text-sm font-medium text-kasmir">
      <div className="flex flex-row gap-4 pt-2.5">
        {!editting && (
          <div className="flex-none">
            <TalentPhotoWithUtilization data={talentData} />
          </div>
        )}
        <div className="flex min-w-0 flex-1 flex-col">
          <div
            className={clsx(
              "mb-1 flex items-baseline",
              editting
                ? "flex-col gap-2.5"
                : "flex-col gap-1 lg:flex-row lg:gap-2.5"
            )}
          >
            <EditableDoubleField
              onFocus={() => setEditting(true)}
              onBlur={() => setEditting(false)}
              first={talentData.first_name}
              last={talentData.last_name}
              displayClassname="text-xl font-bold text-midnight"
              onChange={(first, last) => {
                onUpdateTalent({ first_name: first, last_name: last });
              }}
              inputClass="w-full h-6"
              className="w-full"
              notifyIsEditting={setEditting}
            />

            <div className="text-sm text-midnight">
              {talentData.pronouns ? `(${talentData.pronouns})` : ""}
            </div>
          </div>
          <Field
            name="Preferred name:"
            value={talentData.preferred_name}
            onChange={(v) => onUpdateTalent({ preferred_name: v })}
            stackLeft
            placeholder={talentData.first_name}
          />
          <CurrentRoleEdit
            title={talentData.current_title}
            id={id}
            setEditting={setEditting}
          />
          <AddressInput
            defaultFullAddress={talentData.full_address}
            onSave={(v) => onSaveLocation(v)}
            canDelete={false}
            placeholder="Set location"
          />
        </div>
      </div>
      <div className="mb-1 mt-3.5 flex h-7 flex-row justify-start gap-2">
        {links
          .filter((l) => l.url || l.value)
          .map((l) => {
            return (
              <div key={l.name}>
                {l.clickToCopy ? (
                  <button
                    className="flex h-7 w-7 items-center justify-center rounded-md bg-alice-blue"
                    onClick={() => copyToClipboard(l.value)}
                    data-tooltip-id={`tooltip-${l.name}`}
                  >
                    {l.icon}
                  </button>
                ) : (
                  <Link
                    className="flex h-7 w-7 items-center justify-center rounded-md bg-alice-blue"
                    to={{ pathname: withHttp(l.url) }}
                    target="_blank"
                    data-tooltip-id={`tooltip-${l.name}`}
                  >
                    {l.icon}
                  </Link>
                )}
                <Tooltip id={`tooltip-${l.name}`}>
                  <div className="flex max-w-50 break-all text-sm font-semibold text-electric-indigo underline">
                    {l.clickToCopy ? l.value : l.url}
                  </div>
                </Tooltip>
              </div>
            );
          })}
      </div>
    </div>
  );
}
