import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { admin_talent_overview as $ } from "strings";
import { getTalentNotesV2 } from "utils/adminApi";
import Note from "./Note";
import { getAdminUserId } from "utils/localStorageService";
import DebouncedInput from "components/DebouncedInput";
import LoadPlaceholder from "components/LoadPlaceholder";
import { Button } from "pages/talent/components";
import emptyNotes from "assets/notes.svg";
import PinnedIcon from "assets/icons/Pinned";
import SearchIcon from "assets/icons/Search";
import { TalentPageSection } from "../components/components";
import { MultiSelectDropdown } from "components/DropdownMenuButton";
import { useQuery } from "@tanstack/react-query";
import EmptyState from "../components/EmptyState";

export const NOTE_TYPE_FILTERS = [
  { label: "Show All", value: "all", filter: () => true },
  {
    label: "Recruiter Screen",
    value: "recruiter",
    filter: (type, bodyLower) => {
      if (bodyLower === "status_history") return false;
      return bodyLower.includes(
        "What types of projects would they be interested in from a consulting perspective?".toLowerCase()
      );
    }
  },
  {
    label: "Tech Screen",
    value: "tech_screen",
    filter: (type, bodyLower) => {
      if (type === "status_history") return false;
      return (
        bodyLower.includes("tech screen") || bodyLower.includes("techscreen")
      );
    }
  },
  {
    label: "Probing Questions",
    value: "probing_questions",
    filter: (_, bodyLower) => {
      return bodyLower.includes("probing question");
    }
  },
  {
    label: "Other",
    value: "other",
    filter: (type, bodyLower) => {
      return !NOTE_TYPE_FILTERS.filter(
        (f) => f.value !== "all" && f.value !== "other"
      ).some((f) => f.filter(type, bodyLower));
    }
  }
];

const Notes = ({ setModal }) => {
  const [showPinnedOnly, setShowPinnedOnly] = useState(false);
  const [pinnedCount, setPinnedCount] = useState();

  const customFilter = (n) => {
    if (showPinnedOnly) return n.pinned;
    return true;
  };

  return (
    <TalentPageSection
      title="Notes"
      id="admin_talent_notes"
      topRightContent={
        <div className="flex flex-row gap-2.5">
          <Button
            cancel
            noMinW
            onClick={() => setShowPinnedOnly(!showPinnedOnly)}
          >
            {showPinnedOnly ? (
              "Show All"
            ) : (
              <div className="flex flex-row items-center gap-2">
                <PinnedIcon fill="#3049C5" size="16" />
                See pinned notes {pinnedCount > 0 && ` (${pinnedCount})`}
              </div>
            )}
          </Button>
          <Button noMinW onClick={() => setModal({ type: "add_note" })}>
            Add Note
          </Button>
        </div>
      }
    >
      <NotesView
        customFilter={customFilter}
        setModal={setModal}
        onDisplayedChange={(notes) =>
          setPinnedCount(notes?.filter((n) => n.pinned).length ?? 0)
        }
      />
    </TalentPageSection>
  );
};

const NO_NOTES_DESCRIPTION =
  "There are no notes for this talent yet. Use the input field below to add \
  your first note and click 'Post Note' to save.";

export const NotesView = ({
  customFilter,
  onDisplayedChange,
  setModal,
  availableFilters = NOTE_TYPE_FILTERS
}) => {
  const [notes, setNotes] = useState();
  const { id } = useParams();
  const userId = getAdminUserId();
  const [noteTypeFilter, setNoteTypeFilter] = useState(
    availableFilters.map((f) => f.value)
  );
  const [search, setSearch] = useState("");

  const { data: notesResults } = useQuery({
    queryKey: ["talentNotes", id],
    queryFn: () => {
      return getTalentNotesV2({
        talent_id: id,
        page_size: 9999
      });
    },
    select: (data) => {
      return data.results;
    },
    enabled: !!id
  });

  useEffect(() => {
    if (notesResults) setNotes(notesResults);
  }, [notesResults]);

  const displayedNotes = useMemo(() => {
    return (
      notes
        ?.filter((n) => {
          if (search) {
            // Strip HTML tags before searching
            const plainText = n.note_body.replace(/<[^>]*>/g, "");
            if (!plainText.toLowerCase().includes(search.toLowerCase())) {
              return false;
            }
          }
          return true;
        })
        .filter((n) => (customFilter ? customFilter(n) : true))
        .filter((n) => {
          const body = n.note_body.toLowerCase();

          return noteTypeFilter.some((filter) => {
            return (
              availableFilters
                .find((f) => f.value === filter)
                ?.filter(n.type, body) ?? true
            );
          });
        })
        .sort((a, b) => {
          return b.created_timestamp - a.created_timestamp;
        }) ?? []
    );
  }, [notes, search, noteTypeFilter, customFilter, availableFilters]);

  useEffect(() => {
    if (onDisplayedChange) {
      onDisplayedChange(displayedNotes);
    }
  }, [displayedNotes, onDisplayedChange]);

  return (
    <>
      {/** Header content */}
      <div className="bottom-1 flex w-full flex-col gap-2.5 border-b-2 border-geyser px-4 pb-2">
        <div className="flex w-full flex-row gap-2.5">
          <div className="w-full pb-1">
            <DebouncedInput
              className="placeholder-text-kasmir flex h-8 w-full appearance-none items-center rounded-md border-geyser bg-white px-3 text-sm text-midnight focus:border-2 focus:border-link focus:outline-none"
              placeholder={$.notes_search_placeholder}
              value={search}
              onChange={(s) => setSearch(s)}
              leftIcon={<SearchIcon size="12" fill="#C3C3C3" />}
            />
          </div>
          <div className="w-60">
            <MultiSelectDropdown
              options={availableFilters}
              values={noteTypeFilter}
              setValues={setNoteTypeFilter}
              renderDisplayLabel={(vals) => {
                return vals.includes("all")
                  ? "All Note Types"
                  : `${vals.length} Type${vals.length > 1 ? "s" : ""} Selected`;
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex min-h-0 flex-1 flex-col gap-2.5 px-4 pb-8">
        {!notes ? (
          <div className="flex-1 px-6 pt-6">
            <LoadPlaceholder className="my-4 h-4 w-60" />
            <LoadPlaceholder className="mb-4 h-4 w-60" />
            <LoadPlaceholder className="mb-2 h-4 w-40" />
          </div>
        ) : (
          displayedNotes?.map((n, i) => (
            <Note
              key={i}
              note={n}
              editable={n.updated_by_id === userId}
              highlightedKeyword={search}
              setShowNoteEdit={() =>
                setModal({ type: "edit_note", props: { note: n } })
              }
            />
          ))
        )}
        {notes && displayedNotes.length === 0 && (
          <div className="pt-24">
            <EmptyState
              title={notes.length === 0 ? "No notes yet" : "No notes found"}
              image={emptyNotes}
              description={
                notes.length === 0
                  ? NO_NOTES_DESCRIPTION
                  : "There are no notes that match the search criteria."
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Notes;
