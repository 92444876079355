import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { admin_talent_overview as $ } from "strings";
import { B2G } from "components/Typography";
import CompactSelect from "components/CompactSelect";
import DebouncedInput from "components/DebouncedInput";
import { components } from "react-select";
import UilDirection from "@iconscout/react-unicons/icons/uil-direction";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import QualificationItem from "./QualificationItem";
import SearchIcon from "assets/icons/Search";

const sortOptions = [
  { value: "name", label: $.name_label },
  { value: "rsu_rating", label: $.rsu_rating_label },
  { value: "self_rating", label: $.self_rating_label }
];

const Qualifications = ({
  talentQualifications,
  qualificationsOptions,
  showSelfRatings,
  user,
  talentId
}) => {
  const [type, setType] = useState("all");
  const [dirty, setDirty] = useState(false);
  const [sort, setSort] = useState(sortOptions[0]);
  const [sortDirection, setSortDirection] = useState();
  const [searchString, setSearchString] = useState("");

  const displayed = useMemo(() => {
    // Helper function to normalize qualification type
    const normalizeType = (type) => {
      if (!type) return "skill";
      const t = type.toLowerCase();
      return t === "strategy" ? "skill" : t;
    };

    const result = {
      existing: {
        all: [],
        skill: [],
        tool: [],
        industry: [],
        leadership: []
      },
      available: {
        all: [],
        skill: [],
        tool: [],
        industry: [],
        leadership: []
      }
    };

    // Process existing talent qualifications
    if (Array.isArray(talentQualifications)) {
      // Filter qualifications first
      const filteredQualifications = talentQualifications.filter(
        (q) =>
          q.name.toLowerCase().includes(searchString.toLowerCase()) &&
          (showSelfRatings || q.rsu_rating)
      );

      // Categorize by type
      filteredQualifications.forEach((q) => {
        const t = normalizeType(q.type);

        // Add to all category
        result.existing.all.push(q);

        // Add to specific type category
        if (t in result.existing) {
          result.existing[t].push(q);
        }
      });
    }

    // Process available qualifications (search results)
    if (searchString.length && qualificationsOptions) {
      // Get existing qualification names for faster lookup
      const existingNames = new Set(result.existing.all.map((q) => q.name));

      // Filter and categorize available qualifications
      qualificationsOptions.forEach((q) => {
        // Skip if already exists or doesn't match search
        if (
          existingNames.has(q.name) ||
          !q.name?.toLowerCase().includes(searchString.toLowerCase())
        ) {
          return;
        }

        const t = normalizeType(q.type);

        // Add to all and specific categories
        result.available.all.push(q);

        if (t in result.available) {
          result.available[t].push(q);
        }
      });
    }

    // Sort items of current type
    const sortItems = (items) => {
      let sorted = [...items];

      if (sort.value === "rsu_rating") {
        sorted.sort((a, b) =>
          a.rsu_rating < b.rsu_rating
            ? 1
            : b.rsu_rating < a.rsu_rating
              ? -1
              : a.name.localeCompare(b.name)
        );
      } else if (sort.value === "self_rating") {
        sorted.sort((a, b) =>
          a.talent_rating < b.talent_rating
            ? 1
            : b.talent_rating < a.talent_rating
              ? -1
              : a.name.localeCompare(b.name)
        );
      } else {
        sorted.sort((a, b) => a.name.localeCompare(b.name));
      }

      // Apply reverse sorting if needed
      if (sortDirection === "asc") {
        sorted = sorted.reverse();
      }

      return sorted;
    };

    // Apply sorting to all arrays in the result object
    Object.keys(result.existing).forEach((key) => {
      result.existing[key] = sortItems(result.existing[key]);
    });

    Object.keys(result.available).forEach((key) => {
      result.available[key] = sortItems(result.available[key]);
    });

    // Create the final object with getters that access the current type
    return result;
  }, [
    talentQualifications,
    qualificationsOptions,
    searchString,
    showSelfRatings,
    sort,
    sortDirection
  ]);

  const hasQualification = (name) => {
    return displayed.existing.all.some((q) => q.name === name);
  };

  useEffect(() => {
    setSortDirection();
  }, [sort]);

  const SortButton = () => (
    <button
      onClick={() => setSortDirection(sortDirection === "asc" ? "desc" : "asc")}
      className="group h-6 w-6 focus:outline-none"
    >
      <span
        className={clsx(
          "flex h-6 w-6 appearance-none items-center justify-center rounded focus:outline-none active:bg-geyser group-focus:ring",
          "text-link hover:text-link-dark"
        )}
        tabIndex="-1"
      >
        {!sortDirection && <UilDirection size="22" color="#3049C5" />}
        {sortDirection === "asc" && <UilUp size="22" color="#3049C5" />}
        {sortDirection === "desc" && <UilDown size="22" color="#3049C5" />}
      </span>
    </button>
  );

  const SortIndicator = (props) => {
    return (
      <components.DropdownIndicator
        {...props}
        innerProps={{
          ...props.innerProps,
          onMouseDown: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          onTouchEnd: (e) => {
            e.stopPropagation();
            e.preventDefault();
          }
        }}
      >
        <SortButton />
      </components.DropdownIndicator>
    );
  };

  return (
    <div className="flex flex-1 flex-col">
      <div className="bottom-1 flex w-full flex-col gap-2.5 px-4 pb-2">
        <div className="flex w-full flex-row gap-2.5">
          <div className="w-full pb-1">
            <DebouncedInput
              onChange={(v) => setSearchString(v)}
              value={searchString}
              resetValue={""}
              leftIcon={<SearchIcon size="12" fill="#C3C3C3" />}
              type="text"
              placeholder={$.search_placeholder}
              maxLength="255"
              className="placeholder-text-kasmir flex h-8 w-full appearance-none items-center rounded-md border-geyser bg-white px-3 text-sm text-midnight focus:border-2 focus:border-link focus:outline-none"
            />
          </div>
          <div className="w-52 justify-end">
            <CompactSelect
              placeholder={$.sort_placeholder}
              onChange={(v) => setSort(v)}
              value={sort}
              className="flex h-8 items-center rounded-md bg-white pb-1 pl-2"
              options={sortOptions}
              customStyles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  padding: "0px"
                })
              }}
              components={{
                SingleValue: ({ data, children, ...rest }) => (
                  <components.SingleValue {...rest}>
                    <span className="pr-2 text-sm font-bold text-kasmir">
                      {$.sort_placeholder}:
                    </span>
                    {children}
                  </components.SingleValue>
                ),
                DropdownIndicator: SortIndicator
              }}
            />
          </div>
        </div>

        <div className="rounded-md bg-white p-3.5 pb-0">
          <div className="flex w-full pb-2">
            {["all", "skill", "tool", "industry", "leadership"].map((t) => (
              <button
                onClick={() => setType(t)}
                key={t}
                className={clsx(
                  "-mb-px flex h-8 flex-1 appearance-none items-center justify-start border-b-2 text-sm font-bold text-midnight focus:outline-none",
                  type === t ? "border-midnight" : "border-geyser"
                )}
              >
                {$[`${t}_title`]}
                {!!displayed.existing[t] && (
                  <> ({displayed.existing[t].length})</>
                )}
              </button>
            ))}
          </div>
          <div className="scrollbar flex h-60 flex-col gap-3 overflow-y-auto pb-8">
            {/* Talent Qualifications */}
            {!!displayed.existing[type].length &&
              displayed.existing[type].map((q) => (
                <QualificationItem
                  key={q.id}
                  q={q}
                  selected={true}
                  showSelfRatings={showSelfRatings}
                  checkExists={hasQualification}
                  user={user}
                  talentId={talentId}
                  setDirty={setDirty}
                />
              ))}

            {/* Search Options */}
            {!!displayed.available[type].length && (
              <div
                className={clsx(
                  "flex flex-col gap-2.5 rounded-md bg-lightest-grey p-2.5"
                )}
              >
                <div className="w-full border-b-2 border-alice-blue pb-1 text-sm font-medium text-kasmir">
                  <B2G>Qualifications Not Added</B2G>
                </div>
                {displayed.available[type].map((q) => (
                  <QualificationItem
                    key={q.id}
                    q={q}
                    selected={false}
                    showSelfRatings={showSelfRatings}
                    checkExists={hasQualification}
                    user={user}
                    setDirty={setDirty}
                  />
                ))}
              </div>
            )}

            {searchString.length > 0 &&
              !dirty &&
              !displayed.available[type].length && (
                <div
                  id="search"
                  className="my-8 text-center text-sm text-kasmir"
                >
                  {!displayed.existing[type].length
                    ? "No skills matching your search are currently rated or available to add."
                    : "No additional qualifications to add that match your search."}
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qualifications;
