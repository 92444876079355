import React, {useMemo, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useAlert} from "react-alert";
import {admin_contact_header as $} from "strings";
import {patchContactOverviewV2} from "utils/adminApi";
import {formatPhone, withHttp} from "utils/str";
import AlertError from "components/AlertError";
import Select from "components/CompactSelect";
import EditableField from "components/EditableField";
import EditableDoubleField from "components/EditableDoubleField";
import Input from "components/ListEditable";
import Header from "components/ListHeader";
import Indicator from "components/ListIndicator";
import AddressInput from "components/AddressInput";
import PhoneInput from "components/PhoneEditable";
import UilPhone from "@iconscout/react-unicons/icons/uil-phone";
import UilPin from "@iconscout/react-unicons/icons/uil-map-pin";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import UilLinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import UilEnvelopeSend from "@iconscout/react-unicons/icons/uil-envelope-send";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import {uniqBy} from 'lodash';
import AlertSuccess from "../../../../../components/AlertSuccess";


const pronounsOptions = [
  { label: $.blank_option, value: null },
  { label: $.she_her_option, value: "she/her" },
  { label: $.he_him_option, value: "he/him" },
  { label: $.they_them_option, value: "they/them" },
  { label: $.other_option, value: "other" },
];

const yesNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" }
];

const ContactHeader = ({ data, setData, hubspotInputsDisabled, onDisabledClick }) => {
  const { id } = useParams();
  const alert = useAlert();
  const [hasClip, setHasClip] = useState(false);
  const [states, setStates] = useState({});
  const [editingEmail, setEditingEmail] = useState(false);

  const {
    client_engagement,
    email: login_email,
    contact_owners,
    first_name,
    full_address,
    last_name,
    phone_number,
    preferred_name,
    primary_companies,
    pronouns,
    marketing_excluded
  } = data;


  const emailToDisplay = useMemo(() => {
    if (!login_email || !login_email.trim().length) return;
    const emailToDisplay = login_email;

    return emailToDisplay;
  }, [login_email]);

  const copyEmailToClipboard = () => {
    if (!emailToDisplay) return;
    navigator.clipboard.writeText(emailToDisplay);

    setHasClip(true);
    setTimeout(() => {
      setHasClip(false);
    }, 1000);
  }

  const onSaveLocation = async (location) => {
    const { full_address } = location;

    const origVal = data.full_address;
    setData({ ...data, full_address });
    setStates({ ...states, full_address: "saving" });

    const call = async () => {
      try {
        await patchContactOverviewV2(id, {
          full_address
        });
        setStates({ ...states, full_address: "saved" });
      } catch (e) {
        setStates({ ...states, full_address: "error" });
        setData({ ...data, full_address: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onDeleteLocation = async () => {
    const origVal = data.full_address;
    setData({ ...data, full_address: '' });
    setStates({ ...states, full_address: "saving" });

    const call = async () => {
      try {
        await patchContactOverviewV2(id, {
          full_address: null
        });
          setStates({ ...states, full_address: "saved" });
      } catch (e) {
        setStates({ ...states, full_address: "error" });
        setData({ ...data, full_address: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onSaveName = async (first, last) => {
    const origVals = { first_name, last_name };
    const toSend = {
      first_name: first,
      last_name: last
    };
    setData({ ...data, ...toSend });
    setStates({ ...states, name: "saving" });

    const call = async () => {
      try {
        await patchContactOverviewV2(id, toSend);
        setStates({ ...states, name: "saved" });
      } catch (e) {
        setStates({ ...states, name: "error" });
        setData({ ...data, user: { ...data.user, ...origVals } });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onSaveEmail = async (email) => {
    if (!email) return;
    const origVal = emailToDisplay;
    const toSend = {
      email
    };
    setData({ ...data,  ...toSend });
    setStates({ ...states, login_email: "saving" });

    const call = async () => {
      try {
        await patchContactOverviewV2(id, toSend);
        setStates({ ...states, login_email: "saved" });
        setEditingEmail(false);
      } catch (e) {
        setStates({ ...states, login_email: "error" });
        setData({ ...data, user: { ...data.user, login_email: origVal  }});
        setEditingEmail(false);
        console.error(e);
        const duplicateId = e.response?.data?.users?.[0]?.id;
        if (duplicateId) {
          const contactPageUrl = `/admin/contacts/${duplicateId}/`;
          alert.info(
            <AlertSuccess>
              <div>
                A Contact with this email already exists. <a href={contactPageUrl} className="underline">Click here to view it.</a>
              </div>
            </AlertSuccess>);
        } else {
          alert.error(<AlertError error={e} onRetry={call}/>);
        }
      }
    };
    await call();
  };

  const onSave = async (key, value) => {
    const origVal = data[key];
    setData({ ...data, [key]: value });
    setStates({ ...states, [key]: "saving" });

    const toSend = {
      [key]: value
    };

    const call = async () => {
      try {
        await patchContactOverviewV2(id, toSend);
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onInputClick = () => {
    if (!hubspotInputsDisabled) return;
    onDisabledClick();
  }
  return (
    <div id="admin_contact_header" className="bg-white w-full flex-col p-2">
      <div className="flex w-full">
        <div className="flex w-1/3">
          <div
            className="flex-row w-1/2"
            onClick={onInputClick}
          >
            <div className="flex w-full items-end gap-2 font-bold text-midnight">
              <EditableDoubleField
                first={first_name || (!first_name && !last_name && 'Unknown')}
                last={last_name ||  (!first_name && !last_name &&'Contact')}
                firstPlaceholder={$.first_name_placeholder}
                lastPlaceholder={$.last_name_placeholder}
                firstRequired={true}
                lastRequired={true}
                onChange={(first, last) => {
                  onSaveName(first, last);
                }}
                inputClass="w-28"
                indicator="name"
                className="cursor-default"
                disabled={hubspotInputsDisabled}
              />
              <Select
                className="w-28 mb-px"
                value={
                  pronouns
                    ? pronounsOptions.find((o) => o.value === pronouns)
                    : { label: $.blank_option, value: null }
                }
                options={pronounsOptions}
                placeholder={$.pronouns_placeholder}
                onChange={(v) => onSave("pronouns", v?.value || null)}
                isDisabled={hubspotInputsDisabled}
              />
            </div>
            <div>
              <Input
                defaultValue={preferred_name}
                text={preferred_name || $.add_preferred_name}
                onChange={(v) => {onSave("preferred_name", v)}}
                disabled={hubspotInputsDisabled}
              />
            </div>
          </div>
          <div
            className="flex-row w-1/2"
            onClick={onInputClick}
          >
            <Header Icon={UilPhone} text={$.phone_number_title}>
              <Indicator state={states.phone_number} />
            </Header>
            <PhoneInput
              type="tel"
              name="phone_number"
              text={formatPhone(phone_number)}
              defaultValue={phone_number}
              onChange={(v) => onSave("phone_number", v || null)}
              disabled={hubspotInputsDisabled}
            />
          </div>
        </div>
        <div className="flex-col w-1/4">
          <Header Icon={UilEnvelope} text={$.login_email_title}>
            <Indicator state={states.login_email} />
          </Header>
          <div className="flex items-center gap-2">
            <div className="flex-2 min-w-0">
              <EditableField
                value={emailToDisplay}
                displayValue={emailToDisplay ? emailToDisplay : $.add_email_address}
                copiable={true}
                onCopyClick={copyEmailToClipboard}
                onToggleEdit={() => setEditingEmail(!editingEmail)}
                onSave={(v) => {
                  onSaveEmail(v)
                }}
              />
            </div>
            <div>
              {hasClip &&
                <span className="text-kasmir text-xs font-normal ml-1 italic">
                  {$.copied_clipboard}
                </span>
              }
            </div>
          </div>
        </div>
        <div className="flex-col w-1/4">
        <Header Icon={UilLink} text={$.attached_links_title} />
          <div className="flex items-center gap-2">
            <UilLinkedIn size="14"/>
            {data.linkedin_url ?
              <Link
                className="text-sm font-bold text-midnight truncate"
                to={{pathname: withHttp(data.linkedin_url) }}
                target="_blank"
              >
                {data.linkedin_url}
              </Link> :
              <Link
                className="text-sm font-bold text-kasmir underline"
                to={`/admin/contacts/${id}/contact_info`}
              >
                {$.add_linked_in_link}
              </Link>
            }
          </div>
          <div className="flex items-center gap-2">
            {data.calendar_link ?
              <div className="flex items-center gap-1">
                <UilCalendar size="14"/>
                <Link
                  className="text-sm font-bold text-midnight"
                  to={{pathname: withHttp(data.calendar_link) }}
                  target="_blank"
                >
                  {data.calendar_link}
                </Link>
              </div> :
              data.personal_website ?
              <div className="flex items-center gap-1">
                <UilLink size="14"/>
                <Link
                  className="text-sm font-bold text-midnight"
                  to={{pathname: withHttp(data.personal_website) }}
                  target="_blank"
                >
                  {data.personal_website}
                </Link>
              </div> : <></>
            }
          </div>
        </div>
        <div className="flex-col w-1/6">
          <Header Icon={UilUserCircle} text={$.contact_owner_title} />
          <div className="truncate cursor-pointer">
            <Link
              className="text-sm font-bold text-midnight"
              to={`/admin/contacts/${id}/stakeholders?type=owner`}
            >
              {contact_owners?.length ? [...new Set(contact_owners)].join(', ') :
                <span className="text-kasmir underline"> {$.add_contact_owner_link}</span>
              }
            </Link>
          </div>
        </div>
      </div>
      <div className="flex w-full pt-1">
        <div className="flex w-1/3">
          <div className="flex-row w-1/2">
            <Header Icon={UilBuilding} text={$.primary_company_title} />
            <div className="truncate cursor-pointer">
              <Link
                className="text-sm font-bold text-midnight"
                to={`/admin/contacts/${id}/roles`}
              >
                {primary_companies?.length ? uniqBy(primary_companies, 'id').map((c, i) =>
                  <>{c.name}{uniqBy(primary_companies, 'id').length - 1 === i ? '': ', '}</>
                  ) : <span className="text-kasmir underline"> {$.add_primary_company_link}</span>
                }
              </Link>
            </div>
          </div>
          <div
            className="flex-row w-1/2"
            onClick={onInputClick}
          >
            <Header Icon={UilEnvelopeSend} text={$.marketing_excluded_title}>
              <Indicator state={states.marketing_excluded} />
            </Header>
            <Select
              className="-ml-1 w-20"
              value={yesNoOptions.find((o) => o.value === marketing_excluded)}
              onChange={(v) => onSave("marketing_excluded", v.value || false)}
              options={yesNoOptions}
              isDisabled={hubspotInputsDisabled}
            />
          </div>
        </div>

        <div className="flex-col w-1/4"></div>
        <div
          className="flex-col w-1/4"
          onClick={onInputClick}
        >
          <Header Icon={UilPin} text={$.address_title}>
            <Indicator state={states.full_address} />
          </Header>
          <div className="flex items-center justify-start -mt-1">
            <AddressInput
              defaultFullAddress={full_address}
              onSave={(v) =>  onSaveLocation(v)}
              canDelete={!hubspotInputsDisabled}
              onDelete={() => onDeleteLocation()}
              disabled={hubspotInputsDisabled}
            />
          </div>
        </div>
        <div className="flex-col w/1-4">
          <Header Icon={UilUserCircle} text={$.client_engagement} />
          <div className="truncate cursor-pointer">
            <Link
              className="text-sm font-bold text-midnight"
              to={`/admin/contacts/${id}/stakeholders?type=client_engagement`}
            >
              {client_engagement ? client_engagement :
                <span className="text-kasmir underline"> {$.add_client_engagement_link}</span>
              }
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactHeader;
