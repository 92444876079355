import React, { useState } from "react";
import {useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { admin_talent_invitation_form as $ } from "strings";
import { global as $$ } from "strings";
import { Input, Label, Error } from "components/Form";
import { postTalentEmailInvite } from "utils/adminApi";
import { isTemporalEmail } from "utils/str";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import AlertSuccess from "../../../components/AlertSuccess";
import { Modal } from "pages/talent/components";
import { FormElement } from "pages/talent/Settings/components";

const InvitationForm = ({ onClose, setData, data }) => {
  const alert = useAlert();
  const [emailError, setEmailError] = useState(false);

  const isOriginalTemporal = isTemporalEmail(data.primary_talent_email);

  const { handleSubmit, register, errors, formState } = useForm({
    defaultValues: {
      firstname: data.first_name,
      lastname: data.last_name,
      email: isOriginalTemporal ? "" : data.primary_talent_email
    }
  });

  const onSubmit = async (values) => {
    setEmailError(false);

    const origValues = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.primary_talent_email
    };

    setData({
      ...data,
      onboard_last_email_at: new Date().toISOString()
    });

    const call = async () => {
      try {
        await postTalentEmailInvite({
          email: origValues.email
        });
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {
          timeout: 2000
        });
        onClose();
      } catch (e) {
        if (
          e.response.status === 400 &&
          e.response.data[0].includes("duplicate")
        ) {
          setEmailError(true);
          setData({ ...data, ...origValues });
        } else {
          onClose();
          alert.error(<AlertError error={e} onRetry={call} />);
        }
      }
    };
    await call();
  };

  return (
    <Modal
      title={`Invite ${data.fullname} to create a profile on Pipeline?`}
      saveBtnText="Send Invite"
      isDirty={formState.isDirty}
      onClickSave={handleSubmit(onSubmit)}
      buttonsDisabled={formState.isSubmitting}
      onClose={onClose}
      id="admin_talent_invitation_form"
    >
      <form noValidate className="flex flex-col">
        <p className="mb-2.5">
          Please confirm this information before sending an invitation. Update
          the talent's profile to modify any information shown below.
        </p>
        <div className="flex w-full flex-row gap-2.5">
          <FormElement label="First Name">
            <Input
              name="firstname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.firstname}
              disabled
            />
            {errors.firstname && <Error>{errors.firstname.message}</Error>}
          </FormElement>
          <FormElement label="Last Name">
            <Input
              name="lastname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.lastname}
              disabled
            />
            {errors.lastname && <Error>{errors.lastname.message}</Error>}
          </FormElement>
        </div>
        <div>
          <FormElement label="Email">
            <Input
              name="email"
              type="email"
              maxLength="254"
              ref={register({
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                  message: $.validation_email
                }
              })}
              error={errors.email}
              disabled
            />
            {errors.email && <Error>{errors.email.message}</Error>}
            {emailError && <Error>{$.duplicate_email_error}</Error>}
          </FormElement>
        </div>
      </form>
    </Modal>
  );
};

export default InvitationForm;
